import React from "react";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import MakeUpPageComponent from "../../components/make-up/MakeUp";

const index = () => {
  return (
    <Layout>
      <Seo title="OMG Make Up" />
      <MakeUpPageComponent />
    </Layout>
  );
};

export default index;
