import React from "react";
import qore from "../../context/qoreContext";
import {
  Container,
  Text,
  SimpleGrid,
  Box,
  Image,
  VStack,
  Button,
  Modal,
  useMediaQuery
} from "@chakra-ui/react";
import { StaticImage } from "gatsby-plugin-image";
import Skeleton from "react-loading-skeleton";
import { Link } from "gatsby";
import slugify from "../slugify";
import Slider from "react-slick";
import ProductModal from "../product/ProductModal";
import styled from "styled-components";
import "../../css/banner.css";

const CardImage = styled(Box)`
  background: ${props => props.ringcolor};
  border-radius: 50%;
  margin: 0 auto;
`;

const CustomSlider = styled(Slider)`
  .slick-dots {
    bottom: 16px;
  }
`;

const MakeUp = () => {
  const { data: listProduct, status: statusProduct } = qore.view("makeupProducts").useListRow({
    "$by.parentName": "asc",
    "$by.no": "asc"
  });
  const { data: listBanner, status: statusBanner } = qore.view("makeUpBanner").useListRow({
    "$by.index": "asc"
  });
  const skeletonCount = [1, 2, 3, 4, 5, 6, 7, 8];
  const [modalShow, setModalShow] = React.useState(false);
  const [popupData, setPopupData] = React.useState({});
  const [isMobile] = useMediaQuery("(max-width: 820px)");

  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1500,
    autoplaySpeed: 5000,
    dots: true
  };

  const handleClose = () => {
    setPopupData({});
    setModalShow(false);
  };

  const handleClick = data => {
    const obj = {
      name: data.name,
      imgSwatch: data.imgProduct,
      tokopedia: data.tokopedia,
      shopee: data.shopee,
      lazada: data.lazada,
      blibli: data.blibli,
      tiktok: data.tiktok,
      toko: data.toko,
      whatsapp: data.whatsapp,
      isSachet: data.isSachet,
      type: data.type,
      no: data.no
    };
    setPopupData(obj);
    setModalShow(true);
  };

  return (
    <>
      {listBanner.length && statusBanner === "success" ? (
        <Box overflowX="hidden">
          <CustomSlider {...settings}>
            {listBanner.map(banner => (
              <Box key={banner?.id}>
                <a href={banner?.link} target="_blank" rel="noreferrer">
                  <Image
                    src={isMobile ? banner?.imgMobile : banner?.imgWeb}
                    alt={banner?.name}
                    w="100%"
                    objectFit="contain"
                    loading="lazy"
                  />
                </a>
              </Box>
            ))}
          </CustomSlider>
        </Box>
      ) : (
        <Skeleton height="320px" />
      )}
      <Container maxW={{ lg: "container.md", xl: "container.xl" }} py={10}>
        <a href="https://www.instagram.com/ar/3070444003235363/" target="_blank" rel="noreferrer">
          <StaticImage
            layout="fullWidth"
            objectFit="contain"
            src="../../images/banner/filter-wide.png"
            alt="try-filter"
            loading="lazy"
          />
        </a>
        <Text
          textAlign="center"
          fontWeight="bold"
          fontSize={{ md: "26px", lg: "36px" }}
          mt={10}
          mb={24}>
          Make Up
        </Text>
        <SimpleGrid columns={{ base: 2, md: 4 }} spacing={{ base: 1, md: 8, lg: 10 }}>
          {statusProduct === "success" && listProduct.length
            ? listProduct.map(product => (
                <VStack
                  key={product.id}
                  spacing={8}
                  mb={16}
                  transform="scale(1)"
                  transition="transform 500ms"
                  _hover={{ transform: "scale(1.1)", transition: "transform 500ms" }}>
                  <CardImage
                    h={{ base: "120px", md: "160px", xl: "200px" }}
                    w={{ base: "120px", md: "160px", xl: "200px" }}
                    ringcolor={product.hexColor}>
                    <Link to={`/make-up/details/${slugify(product?.parentName)}/${product.no}`}>
                      <Image
                        src={product?.imgProduct}
                        alt={product?.name}
                        w={{ base: "120px", md: "150px", xl: "180px" }}
                        h={{ base: "160px", md: "200px", xl: "240px" }}
                        objectFit="contain"
                        loading="lazy"
                        m="0 auto"
                        position="relative"
                        bottom="20px"
                        transform="rotate(-12deg)"
                      />
                      {product?.bestSeller && (
                        <Box
                          position="absolute"
                          right={{ base: "0", xl: "48px" }}
                          maxH={{ base: "30px", md: "32px", lg: "32px", xl: "40px" }}
                          top="4px">
                          <StaticImage
                            src="../../images/best-seller2.png"
                            alt="best-seller"
                            loading="lazy"
                            objectFit="contain"
                            style={{ maxHeight: "inherit" }}
                          />
                        </Box>
                      )}
                    </Link>
                  </CardImage>
                  <Box textAlign="left">
                    <Link to={`/make-up/details/${slugify(product?.parentName)}/${product.no}`}>
                      <Text mb={3}>{product.parentName}</Text>
                      <Text mb={3} fontWeight="bold">{`${product?.no} ${product?.name}`}</Text>
                      <Text mb={3}>{`Rp ${product?.price.toLocaleString("id-ID", {
                        maximumFractionDigits: 0
                      })}`}</Text>
                    </Link>
                    <Box mt={6} textAlign="center">
                      <Button
                        size="sm"
                        width={"88px"}
                        rounded="xl"
                        bg="#262626"
                        colorScheme="blackAlpha"
                        color="white"
                        onClick={() => handleClick(product)}
                        _focus={{ boxShadow: "none" }}
                        _hover={{
                          bg: "black"
                        }}>
                        Beli
                      </Button>
                    </Box>
                  </Box>
                </VStack>
              ))
            : skeletonCount.map(skeleton => (
                <Box
                  key={skeleton}
                  h={{ base: "200px", lg: "240px", xl: "400px" }}
                  w={{ base: "150px", lg: "180px", xl: "300px" }}
                  margin="0 auto">
                  <Skeleton height="100%" width="100%" />
                </Box>
              ))}
        </SimpleGrid>
      </Container>
      <Modal
        isOpen={modalShow}
        size={isMobile ? "sm" : "2xl"}
        onClose={() => handleClose()}
        isCentered={true}>
        <ProductModal PopUpData={popupData} />
      </Modal>
    </>
  );
};

export default MakeUp;
